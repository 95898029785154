import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useBi, useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { useCurrentStateAndParams } from '@wix/tpa-router/react';
import { groupCreatePostClick, groupFeedView } from '@wix/bi-logger-groups/v2';
import cls from 'classnames';

import {
  type RichContent,
  type RicosEditorType,
  EditorEventsProvider,
} from '@wix/ricos';

import { EFilterKeys } from '@wix/social-groups-serverless/dist/feed/constants';
import {
  selectDialog,
  selectFeedPermissionsByGroup,
  selectGroupIdBySlug,
  selectTopic,
  selectFeed,
  selectFeedItems,
  selectGroupMembership,
} from 'store/selectors';
import { GroupAppKey } from 'store/groups/types';

import { useController } from 'common/context/controller';
import { useDidUpdate } from 'common/hooks';

import { FeedItemList } from 'common/components/FeedItemList';
import { CreatePostCard } from 'common/components/CreatePostCard';
import { FeedItemEditorDialog } from 'common/components/FeedItemEditorDialog';
import { type IntentType } from 'common/components/Ricos/types';

import { RestrictedState } from 'Group/Widget/RestrictedState';
import { Show } from 'wui/Show';

import { AppliedFilters } from './AppliedFilters';
import { GroupTopics } from '../GroupTopics';
import { SeoPagination } from './SeoPagination';
import { Onboarding } from '../Onboarding';
import classes from './GroupFeed.scss';

export interface IGroupFeedProps {}

export const GroupFeed: React.FC<IGroupFeedProps> = () => {
  const bi = useBi();
  const { application$, feed$, group$ } = useController();
  const { params } = useCurrentStateAndParams();
  const { isMobile, isCssPerBreakpoint } = useEnvironment();
  const { experiments } = useExperiments();

  const groupId = useSelector(selectGroupIdBySlug(params.slug));
  const permissions = useSelector(selectFeedPermissionsByGroup(groupId));
  const dialog = useSelector(selectDialog('createPost'));
  const topic = useSelector(selectTopic(params[EFilterKeys.TOPICS]));
  const membership = useSelector(selectGroupMembership(groupId));

  const state = useSelector(selectFeed);
  const items = useSelector(selectFeedItems);

  const $editor = React.useRef<RicosEditorType>(null);
  const [draft, setDraft] = React.useState<RichContent | undefined>(
    dialog.params?.draft,
  );

  useDidUpdate(() => {
    if (permissions.canViewPosts) {
      feed$.fetch(groupId);
    }
  }, [membership, permissions.canViewPosts]);

  useEffect(() => {
    setDraft(dialog.params?.draft);
  }, [dialog.params?.draft]);

  React.useEffect(() => {
    bi.report(
      groupFeedView({
        group_id: groupId,
        origin: 'discussion_tab',
      }),
    );
  }, []);

  const displayOnTablet =
    isCssPerBreakpoint && experiments.enabled('specs.groups.ResizeTopics');

  return (
    <RestrictedState
      application={GroupAppKey.FEED_APP}
      data-hook="restricted-state"
    >
      <AppliedFilters
        className={cls(classes.filters, classes.prior, {
          [classes.displayOnTablet]: displayOnTablet,
        })}
      />

      <Show if={permissions.canCreatePosts}>
        <CreatePostCard
          content={draft}
          data-hook="create-post-card"
          onClick={handleCreatePostClick}
        />
      </Show>

      <Onboarding
        className={cls(classes.onboarding, classes.prior, {
          [classes.displayOnTablet]: displayOnTablet,
        })}
        groupId={groupId}
      />
      <GroupTopics
        inline={isMobile || isCssPerBreakpoint}
        groupId={groupId}
        className={cls(classes.topics, classes.prior, {
          [classes.displayOnTablet]: displayOnTablet,
        })}
      />

      <FeedItemList
        state={state}
        items={items}
        onCreatePost={handleCreatePostClick}
        onFetch={handleFetchMore}
        data-hook="feed-item-list"
        origin="GroupFeed"
      />
      <SeoPagination groupId={groupId} />
      <EditorEventsProvider>
        <FeedItemEditorDialog
          draft={draft}
          forTopic={topic}
          groupId={groupId}
          editorRef={$editor}
          isOpen={dialog.isOpen}
          biExtra={dialog.params}
          onClose={closeCreateModal}
          origin="GroupFeed"
          intent={dialog.params?.intent}
          data-hook="feed-item-editor-dialog"
        />
      </EditorEventsProvider>
    </RestrictedState>
  );

  function handleFetchMore(cursor?: string) {
    feed$.fetch(groupId, { cursor }, true);
  }

  function handleCreatePostClick(intent?: IntentType) {
    bi.report(
      groupCreatePostClick({
        groupId,
        origin: 'top_rce_area',
      }),
    );

    group$.openCreatePostDialog(groupId, {
      intent,
      origin: 'livesite_group_page',
      page_name: 'livesite_group_page',
    });
  }

  function closeCreateModal(event?: React.SyntheticEvent, draft?: RichContent) {
    setDraft(draft);
    application$.closeDialog('createPost');
  }
};

GroupFeed.displayName = 'GroupFeed';
