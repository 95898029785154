import React from 'react';
import { useSelector } from 'react-redux';
import cls from 'classnames';

import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { groupFeedTopicsSelectTopicInFeed } from '@wix/bi-logger-groups/v2';
import { useCurrentStateAndParams } from '@wix/tpa-router/react';

import {
  selectAllTopics,
  selectFeedPermissionsByGroup,
  selectTopicsState,
} from 'store/selectors';
import { EFilterKeys } from '@wix/social-groups-serverless/dist/feed/constants';

import { useDidUpdate } from 'common/hooks';
import { useController } from 'common/context/controller';
import { TopicLink } from 'common/components/TopicLink';

import { TextButton } from 'wui/TextButton';
import { Card } from 'wui/Card';
import { CardHeader } from 'wui/CardHeader';
import { CardContent } from 'wui/CardContent';
import { CardActions } from 'wui/CardActions';
import { List } from 'wui/List';
import { Show } from 'wui/Show';
import { Hide } from 'wui/Hide';
import { Skeleton } from 'wui/Skeleton';

import classes from './GroupTopics.scss';

export interface IGroupTopicsProps {
  groupId: string;
  inline?: boolean;
  className?: string;
}

export function GroupTopics(props: IGroupTopicsProps) {
  const { groupId, inline, className } = props;

  const { t } = useTranslation();
  const { topics$ } = useController();
  const { isMobile, isCssPerBreakpoint } = useEnvironment();
  const { params } = useCurrentStateAndParams();

  const topics = useSelector(selectAllTopics);
  const topicState = useSelector(selectTopicsState);
  const permissions = useSelector(selectFeedPermissionsByGroup(groupId));

  const [collapsed, setCollapsed] = React.useState(!isMobile);
  const activeId = params[EFilterKeys.TOPICS];
  const isLoading = topicState.statuses.fetch.pending;

  const bi = useBi();

  useDidUpdate(() => {
    if (permissions.canViewPosts) {
      topics$.fetch(groupId);
    }
  }, [permissions.canViewPosts]);

  if (!topics.length || !permissions.canViewPosts) {
    return null;
  }

  const topicsToShow = collapsed ? topics.slice(0, 10) : topics;

  return (
    <Card
      sideBorders={!isMobile}
      topBottomBorders={!isMobile}
      className={cls(classes.root, className, {
        [classes.inline]: inline,
      })}
    >
      <CardHeader
        wired
        title={
          inline ? undefined : t('groups-web.discussion.topics.widget.title')
        }
      />
      <CardContent
        className={cls({ [classes.cardContent]: isCssPerBreakpoint })}
      >
        <List
          disablePadding
          wrap
          direction="horizontal"
          gap="SP2 SP3"
          scrollable={isMobile}
        >
          <Show if={isLoading}>
            <Skeleton variant="rect" width={90} height={22} />
            <Skeleton variant="rect" width={80} height={22} />
            <Skeleton variant="rect" width={90} height={22} />
            <Skeleton variant="rect" width={110} height={22} />
          </Show>
          <Hide if={isLoading}>
            {topicsToShow.map((topic) => (
              <li key={topic.id} style={{ maxWidth: '100%' }}>
                <TopicLink
                  wired
                  withCounter={!isMobile}
                  groupId={groupId}
                  topic={topic}
                  isRemovable={topic.id === activeId}
                  active={topic.id === activeId}
                  className={classes.topic}
                  ellipsis
                  linkProps={{
                    bi: groupFeedTopicsSelectTopicInFeed({
                      groupId: groupId as string,
                      origin: 'feed_topics_section',
                    }),
                    className: classes.link,
                  }}
                />
              </li>
            ))}
            <Show if={topics.length > 10 && inline}>
              <TextButton wired onClick={toggleCollapsed}>
                {collapsed
                  ? t('groups-web.discussion.topics.widget.more')
                  : t('groups-web.discussion.topics.widget.less')}
              </TextButton>
            </Show>
          </Hide>
        </List>
      </CardContent>
      <Show if={topics.length > 10 && !inline}>
        <CardActions>
          <TextButton wired onClick={toggleCollapsed}>
            {collapsed
              ? t('groups-web.discussion.topics.widget.more')
              : t('groups-web.discussion.topics.widget.less')}
          </TextButton>
        </CardActions>
      </Show>
    </Card>
  );

  function toggleCollapsed() {
    setCollapsed(!collapsed);
  }
}

GroupTopics.displayName = 'GroupTopics';
